import React, { useEffect, useState } from 'react';

function Contact() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="min-h-screen bg-white relative overflow-hidden">
      <div className="relative max-w-3xl mx-auto px-4 md:px-8 py-12 md:py-24">
        <h2 
          className="text-2xl md:text-3xl font-light mb-8 md:mb-16 text-gray-800"
          style={{
            transform: isMobile ? 'none' : `translateY(${scrollPosition * 0.1}px)`,
            textShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
          }}
        >
          Contact
        </h2>
        
        <div className="space-y-6 md:space-y-8 text-base md:text-lg leading-relaxed text-gray-700">
          <p 
            style={{
              transform: isMobile ? 'none' : `translateY(${scrollPosition * 0.05}px)`,
              textShadow: '0 2px 3px rgba(0, 0, 0, 0.08)'
            }}
          >
            Email: ls3drawings@gmail.com
          </p>
          
          <p 
            style={{
              transform: isMobile ? 'none' : `translateY(${scrollPosition * 0.07}px)`,
              textShadow: '0 2px 3px rgba(0, 0, 0, 0.08)'
            }}
          >
            Instagram: @lowell_is_still_drawing
          </p>
          
          <p 
            style={{
              transform: isMobile ? 'none' : `translateY(${scrollPosition * 0.09}px)`,
              textShadow: '0 2px 3px rgba(0, 0, 0, 0.08)'
            }}
          >
            Location: Queens, NY
          </p>
        </div>
      </div>
    </div>
  );
}

export default Contact;