import React, { useState, useEffect } from 'react';

function ParallaxBackground({ mousePosition, zoomLevel = 1 }) {
  const [videoSource, setVideoSource] = useState('');

  const videos = [
    '/videos/video1.mp4',
    '/videos/video2.mp4',
    '/videos/video3.mp4',
     // Add more videos if needed
  ];

  useEffect(() => {
    // Randomly select a video on mount
    const randomIndex = Math.floor(Math.random() * videos.length);
    setVideoSource(videos[randomIndex]);
  }, []);

  const calculateZoomedTranslation = (mousePosition, zoomLevel) => {
    const translateX = mousePosition.x * (1 - 1 / zoomLevel);
    const translateY = mousePosition.y * (1 - 1 / zoomLevel);

    return {
      x: translateX,
      y: translateY,
    };
  };

  const zoomedTranslation = calculateZoomedTranslation(mousePosition, zoomLevel);

  if (!videoSource) {
      return null; // or a loading spinner, if desired
  }

  return (
    <div className="fixed inset-0 z-0 overflow-hidden">
      <div className="w-full h-full overflow-hidden">
        <video
          autoPlay
          muted
          loop
          playsInline
          className="w-full h-full object-cover transition-transform duration-200 ease-out"
          style={{
            transform: `translate(${zoomedTranslation.x}px, ${zoomedTranslation.y}px) scale(${zoomLevel})`,
          }}
        >
          <source src={videoSource} type="video/mp4" />
          {/* Fallback Image */}
          <img src="/images/fallback-image.jpg" alt="Fallback Image" />
        </video>
      </div>
    </div>
  );
}

export default ParallaxBackground;