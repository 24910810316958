import React, { useState, useEffect, useRef } from 'react';
import LandingHeader from './LandingHeader';
import ParallaxBackground from './ParallaxBackground';
import Gallery from './Gallery';
import Navigation from './Navigation';
import ArtistStatement from './ArtistStatement';
import Contact from './Contact';
import DrawingArchive from './DrawingArchive';
import PageTransition from './PageTransition';

function LandingPage() {
  const [entered, setEntered] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [currentPage, setCurrentPage] = useState('gallery');
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [displayedPage, setDisplayedPage] = useState('gallery');

  const pageComponents = useRef({
    gallery: <Gallery key="gallery" />,
    drawings: <DrawingArchive key="drawings" />,
    statement: <ArtistStatement key="statement" />,
    contact: <Contact key="contact" />,
  }).current;

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: (e.clientX / window.innerWidth) * 15,
        y: (e.clientY / window.innerHeight) * 15,
      });
    };
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handlePageChange = (newPage) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setDisplayedPage(newPage);
      setIsTransitioning(false);
    }, 1000);
    setCurrentPage(newPage);
  };

  const renderPage = () => {
    return pageComponents[displayedPage] || pageComponents.gallery;
  };

  return (
    <div className="relative min-h-screen w-full overflow-hidden">
      <div
        className={`transition-opacity ${entered ? 'opacity-0' : 'opacity-100'}`}
        style={{ transitionDuration: '3s' }}
      >
        <ParallaxBackground mousePosition={mousePosition} />
        <LandingHeader onEnter={() => setEntered(true)} />
      </div>

      <div
        className={`fixed inset-0 bg-[#ffffff] transition-opacity ${
          entered ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        style={{ transitionDuration: '3s' }}
      >
        <div
          className={`transition-opacity duration-1000 ${
            entered ? 'opacity-100 delay-[3s]' : 'opacity-0'
          }`}
        >
          <Navigation onPageChange={handlePageChange} />
          <PageTransition isVisible={!isTransitioning}>
            {renderPage()}
          </PageTransition>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;