import React, { useState, useEffect } from 'react';
import image2 from '../images/image2.JPG';
import image3 from '../images/image3.JPG';
import image4 from '../images/image4.jpg'; 
import image5 from '../images/image5.jpg';
import image6 from '../images/image6.jpg';// Import image5
import './Gallery.css';

function Gallery() {
  const galleryItems = [
    { 
      src: image3, 
      alt: "Selected Work 2", 
      title: "CHIHIRO",
      bottomText: "Acrylic on Canvas, 36''x 48'' 2023",
      description: "SOLD",
      isLarge: true
    },
    { 
      src: image2, 
      alt: "Selected Work 1", 
      title: "Death stares at the moon",
      bottomText: "Acrylic & Graphite on Canvas, 6'' x 6'', 2025",
      description: "AVAILABLE",
      isLarge: false
    },
    { // Add image4 object
      src: image4,
      alt: "Selected Work 3",
      title: "WIldfire",
      bottomText: "Acrylic on Canvas, 6'' x 6'', 2025",
      description: "AVAILABLE/SOLD",
      isLarge: false, 
    },
    { // Add image4 object
      src: image5,
      alt: "Selected Work 5",
      title: "5 RED TRYSTRAMS FIELD 22 P35",
      bottomText: "Pastel, Graphite on Paper, 5 - 1/2 '' x 8'', 2022",
      description: "AVAILABLE/SOLD",
      isLarge: false, 
    },
    { // Add image4 object
      src: image6,
      alt: "Selected Work 5",
      title: "16 MUJI A4 23 2451",
      bottomText: "Pencil on Paper, A4, 2024",
      description: "AVAILABLE/SOLD",
      isLarge: false, 
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === galleryItems.length - 1 ? 0 : prevIndex + 1
      );
    }, 300);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 600);
  };

  const handlePrevious = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === 0 ? galleryItems.length - 1 : prevIndex - 1
      );
    }, 300);
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 600);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const getContainerClasses = () => {
    const baseClasses = "flex flex-col relative transition-all duration-1000 ease-in-out";
    if (isMobile) {
      return `${baseClasses} w-full px-4`;
    }
    return `${baseClasses} ${galleryItems[currentIndex].isLarge ? 'w-full' : 'w-1/2'}`;
  };

  const getImageClasses = () => {
    const baseClasses = "w-full h-auto object-cover transition-opacity duration-600";
    return `${baseClasses} ${isTransitioning ? 'opacity-0' : 'opacity-100'}`;
  };

  return (
    <div className="p-4 md:p-8 flex flex-col items-center justify-start">
      <div className="container mx-auto max-w-6xl">
        {loading ? (
          <div className="flex justify-center items-center h-48">
            <p className="text-gray-600">Loading...</p>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center">
            <div className={getContainerClasses()}>
              <h1 className="font-light text-xl md:text-2xl tracking-wider text-black mb-2 md:mb-4 text-left transition-all duration-1000">
                {galleryItems[currentIndex].title}
              </h1>
              
              <div className="gallery-image-container rounded-lg overflow-hidden shadow-lg relative group">
                <img 
                  src={galleryItems[currentIndex].src} 
                  alt={galleryItems[currentIndex].alt} 
                  className={getImageClasses()}
                />
                
                <div className="absolute inset-0 flex items-center justify-between opacity-100 md:opacity-0 md:group-hover:opacity-100 transition-opacity duration-300">
                  <button 
                    onClick={handlePrevious}
                    className="mx-2 md:mx-4 p-2 md:p-3 bg-white bg-opacity-75 rounded-full hover:bg-opacity-100 transition-all"
                    aria-label="Previous image"
                  >
                    ←
                  </button>
                  <button 
                    onClick={handleNext}
                    className="mx-2 md:mx-4 p-2 md:p-3 bg-white bg-opacity-75 rounded-full hover:bg-opacity-100 transition-all"
                    aria-label="Next image"
                  >
                    →
                  </button>
                </div>
              </div>

              <h2 className="font-light text-lg md:text-2xl tracking-wider text-black mt-2 md:mt-4 text-left transition-all duration-1000">
                {galleryItems[currentIndex].bottomText}
              </h2>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Gallery;