import React from 'react';

function ArtistStatement() {
  return (
    <div className="h-screen overflow-y-auto bg-white">
      <div className="relative max-w-3xl mx-auto px-4 md:px-8 py-12 md:py-24">
        <h1 className="text-2xl md:text-3xl font-light mb-8 md:mb-16 text-gray-800">
          Artist Statement
        </h1>
        
        <div className="space-y-6 md:space-y-8 text-base md:text-lg leading-relaxed text-gray-700 mb-12 md:mb-16">
          <p>
          Lowell Stephens is a Queens-based multidisciplinary artist merging architectural precision with the fluidity of abstract figuration. Born in New York City and trained in architectural technology, this work bridges the structured world of design and the raw spontaneity of personal expression. Drawing serves as his foundational language, where the interplay of memory, observation, and materiality transforms sketches and photographs into bold, layered works on paper and canvas.
          </p>
          
          <p>
          Lowell's process thrives on “controlled spontaneity”—a dialogue between intention and accident. Each series evolves organically, guided by an ever-expanding archive of thousands of sketches and reference materials. His work invites viewers to navigate the tension between abstraction and human form, reflecting his dual life as a full-time inspector, father, and artist.
          </p>
          
          <p>
          Selected for programs like the Artist as Entrepreneur initiative (Flushing Town Hall) and NO YOU TELL IT, Lowell continues to explore themes of impermanence and reinvention, culminating in his upcoming 36-piece series marking his 36th year—a visual diary of past, present, and speculative futures.
          </p>

          <p>
          </p>
        </div>
      </div>
    </div>
  );
}

export default ArtistStatement;