// LandingHeader.js
import React from 'react';
import Button from './Button'

function LandingHeader({ onEnter }) {
  return (
    <div className="relative z-10 w-full">
      <div className="flex justify-between items-center p-6">
      <h1 className="text-2xl text-black font-light tracking-wider backdrop-blur-sm bg-white/30 px-4 py-2">
            Lowell Stephens
        </h1>
         <Button onClick={onEnter} >ENTER</Button>
     </div>
   </div>
    );
}
export default LandingHeader;