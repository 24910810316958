import React from 'react';
import Button from './Button';

function DrawingArchive() {
  return (
    <div className="h-screen overflow-y-auto bg-white">
      <div className="relative max-w-3xl mx-auto px-4 md:px-8 py-12 md:py-24">
        <h1 className="text-2xl md:text-3xl font-bold mb-8 md:mb-16 text-gray-800 border-b pb-2">
          Drawing Archive
        </h1>
        
        <a 
          href="https://drawings.lowellstephens.com" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <Button>
            ENTER 
          </Button>
        </a>
      </div>
    </div>
  );
}

export default DrawingArchive;