// Button.js
import React from 'react';

function Button({ onClick, children, className = '' }) {
  return (
    <button
      onClick={onClick}
      className={`px-6 py-2 border border-black text-black text-base tracking-wider backdrop-blur-sm bg-white/30 hover:bg-[#FF6600] hover:text-white hover:border-[#FF6600] transition-all duration-300 ease-in-out ${className}`}
    >
      {children}
    </button>
  );
}

export default Button;