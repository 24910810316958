import React, { useState, useEffect } from 'react';

function Navigation({ onPageChange }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = (page) => {
    if (onPageChange) {
      onPageChange(page);
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="w-full py-4 px-4 md:px-8 border-b border-gray-300">
      <div className="container mx-auto max-w-6xl flex justify-between items-center">
        <div className="text-lg md:text-xl text-gray-800 font-light">Lowell Stephens</div>
        
        {isMobile ? (
          <>
            <button 
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="text-2xl text-gray-800"
              aria-label="Menu"
            >
              ☰
            </button>
            
            {isMenuOpen && (
              <div className="fixed inset-0 bg-white z-50 flex flex-col items-center justify-center">
                <button 
                  onClick={() => setIsMenuOpen(false)}
                  className="absolute top-4 right-4 text-3xl text-gray-800"
                  aria-label="Close menu"
                >
                  ×
                </button>
                <div className="flex flex-col gap-8 text-center">
                  <button 
                    onClick={() => handleClick('gallery')}
                    className="text-xl text-gray-800 hover:text-gray-600 transition-colors"
                  >
                    Survey
                  </button>
                  <button 
                    onClick={() => handleClick('drawings')}
                    className="text-xl text-gray-800 hover:text-gray-600 transition-colors"
                  >
                    Drawing Archive
                  </button>
                  <button 
                    onClick={() => handleClick('statement')}
                    className="text-xl text-gray-800 hover:text-gray-600 transition-colors"
                  >
                    Artist Statement
                  </button>
                  <button 
                    onClick={() => handleClick('contact')}
                    className="text-xl text-gray-800 hover:text-gray-600 transition-colors"
                  >
                    Contact
                  </button>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="flex gap-8">
            <button 
              onClick={() => handleClick('gallery')}
              className="text-gray-800 hover:text-gray-600 transition-colors"
            >
              Survey
            </button>
            <button 
              onClick={() => handleClick('drawings')}
              className="text-gray-800 hover:text-gray-600 transition-colors"
            >
              Drawing Archive
            </button>
            <button 
              onClick={() => handleClick('statement')}
              className="text-gray-800 hover:text-gray-600 transition-colors"
            >
              Artist Statement
            </button>
            <button 
              onClick={() => handleClick('contact')}
              className="text-gray-800 hover:text-gray-600 transition-colors"
            >
              Contact
            </button>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navigation;